function fileUploadWidget() {
  $('.file-upload-widget input').on('change', function (e) {
    console.log($(this).parent('file-upload-widget').find('.selected-file'));
    const $fileUploadWidget = $(this).parents('.file-upload-widget');
    $fileUploadWidget.find('.selected-file').html($(this).val().split('\\').pop());
    $fileUploadWidget.find('.remove').removeClass('hidden');
    $();
  });
  $('.file-upload-widget .remove').on('click', function (e) {
    e.preventDefault();
    const $fileUploadWidget = $(this).parents('.file-upload-widget');
    $fileUploadWidget.find('input').val('');
    $fileUploadWidget.find('.selected-file').html('');
    $(this).addClass('hidden');
  });
}
$(document).ready(fileUploadWidget);
