// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;

$(document).ready(function() {
  $(document).on('click', '.box-header a', function (e) {
    e.stopPropagation();
  });

  $(document).on('click', '.nav-toggle-button', function(e) {
    e.preventDefault();
    $('#sidebar').find('nav').toggleClass('open');
  });
});
