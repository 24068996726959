$(document).ready(function() {
  $(document).on('click', '[data-modal="true"]', function (e) {
    e.stopPropagation();
    e.preventDefault();
    const modal = $('#modal');
    const modalTitle = $(this).data('modal-title');

    $.ajax({
      url: $(this).attr('href'),
    }).done(function (data) {
      modal.find('#response').empty().removeClass();
      modal.find('.modal-body').html(data);
      if (modalTitle == '' || modalTitle == undefined) {
        modal.addClass('no-title');
      } else {
        modal.removeClass('no-title');
      }
      modal.find('.modal-title').html(modalTitle);
      if (!(modal.data('bs.modal') || {}).isShown) {
        modal.modal('show');
      }
    }).fail(function (data) {
      // Optionally alert the user of an error here...
    });
  });
});
