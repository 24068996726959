$(document).ready(function () {
  $(document).on('submit', '[data-ajax-form="true"]', function (e) {
    e.preventDefault();
    const form = $(this);

    // do we need confirmation?
    if (true || (form.data('confirmation') == true && confirm('Do you really want to submit the form?'))) {
      $.ajax({
        type: 'POST',
        url: form.attr('action'),
        data: new FormData(this),
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'json',
        success: function (data, status, xhr) {
          const ct = xhr.getResponseHeader('content-type') || '';
          if (ct.indexOf('json') > -1) {
            // are we successful?
            if (data.type == 'success') {
              form.find('.form-group').removeClass('has-error').addClass('has-success');

              // if form is inside a modal
              const modal = form.parents('.modal');
              if (modal.length > 0) {
                if (data.type == 'success') {
                  modal.addClass('success');
                  setTimeout(function () {
                    modal.modal('toggle');
                    modal.removeClass('success');
                  }, 1500);
                } else {
                }
                modal.find('.modal-content').addClass('success');
              } else {
                // no modal
                const message = $('<div class="alert alert-success"></div>');
                message.html(data.message);
                form.find('.response').html(message);
              }
              if (data.redirect) {
                setTimeout(function () {
                  window.location.href = data.redirect;
                }, 1500);
              }
            } else {
              let newForm = $(data.replace);
              if (!newForm.is('form')) {
                newForm = newForm.find('form');
              }
              form.html(newForm.html());
            }
          }
        },
        error: function (data, status, object) {
          const errorReason = data.responseText.replace(/^[\s\S]*<title.*?>|<\/title>[\s\S]*$/ig, '');
          const message = $('<div class="alert alert-danger"></div>');
          message.html(errorReason);
          form.find('.response').html(message);
        },
      });
    }
  });
});
