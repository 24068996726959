$(document).ready(function () {
  let $tableBody = $('tbody');
  let $searchField = $("#search-field");
  let url = $("#search-field").data('searchUrl');
  let timer;

  function fireSearch(query) {
    $.ajax({
      url: url,
      type: 'GET',
      data: {'query': query}
    }).done(function (data) {
      $tableBody.empty();
      $tableBody.append(data);
    });
  }

  function onInput(query) {
    clearTimeout(timer);
    timer = setTimeout(function() {
      fireSearch(query);
    }, 1000);
  }

  $searchField.keyup(function (e) {
    onInput($(this).val());
  });
});
