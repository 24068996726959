require('jquery');
require('bootstrap/dist/js/npm.js');
require('@fortawesome/fontawesome-pro/js/all');
require('simplemde');
require('bootstrap-flat/css/bootstrap-flat.min.css');
require('bootstrap-flat/css/bootstrap-flat-extras.min.css');
require('devbridge-autocomplete');
require('stupid-table-plugin');
require('ionicons');

require('../js/ajax_form.js');
require('../js/ajax_modal.js');
require('../js/ajax_search.js');
require('../js/form.js');
require('../js/general.js');
require('../js/autocomplete_module.js');
// require('../js/autocomplete.js');

require('bootstrap/dist/css/bootstrap.css');
require('../sass/layout.sass');
require('../sass/login.sass');
require('../sass/admin_customer.sass');

require('../sass/admin_products.sass');
require('../sass/ajax_forms.sass');
require('../sass/autocomplete.sass');
require('../sass/form.sass');
require('../sass/general.sass');
require('../sass/notification.sass');
require('../sass/product.sass');
require('../sass/support.sass');
require('../sass/table.sass');

require('@fortawesome/fontawesome-pro/scss/fontawesome.scss');
